<!--  -->
<template>
  <div class=''>
    <div style="font-size: 28px;">我的成员管理</div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-input style="width: 500px" placeholder="请输入用户姓名查询" v-model="search" clearable>
    </el-input>
    <div align='right' class="indicate_legend">
      <span class="blank" style="background-color:#F6BEB8 ">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏高</span>
      <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style="background-color:rgb(234 215 79) ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span style=" "> 偏低</span>

    </div>
    <el-table :data="tableData.filter(data => !search || data.realName.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" border :header-cell-style="{ 'text-align': 'center' }" :cell-style="cellStyle">

      <el-table-column type="index" width="40"></el-table-column>


      <el-table-column label="用户姓名" prop="uname" width="80">
        <!-- <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.uname">
            </el-input>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-button @click="updateRealName(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template> -->
      </el-table-column>
      <el-table-column label="手机号" prop="phone" width="120"></el-table-column>
      <!-- <el-table-column label="个人信息"  width="150">
          <template slot-scope="scope">
            <el-button @click="psInfo(scope.row)" type="primary">{{ '个人信息表' }}</el-button>
          </template>
        </el-table-column> -->

      <el-table-column label="孕婴项目" width="100">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <!-- <el-button @click="getECG(scope3.row.uid)">
                静态心电
              </el-button>
              <el-button @click="getECGHolter(scope3.row.uid)">
                动态心电
              </el-button> -->
            <!-- <el-button @click="get8ECGHolter(scope3.row.uid)">
                8导联
              </el-button> -->
            <el-button @click="getTem(scope3.row.uid)">
              妇温宝
            </el-button>
            <el-button @click="getBaby(scope3.row.uid)">
              胎心仪
            </el-button>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <el-button @click="getBloodPressure(scope3.row.uid)">
              血压
            </el-button>
            <el-button @click="getBloodOxygen(scope3.row.uid)">
              血氧
            </el-button>
            <el-button @click="getBodyTemprature(scope3.row.uid)">
              体温
            </el-button>
            <el-button @click="getThreeInOne(scope3.row.uid)">
              三合一
            </el-button>
            <el-button @click="getBodyFat(scope3.row.uid)">
              体脂
            </el-button>
            <el-button @click="getUrine(scope3.row.uid)">
              尿检
            </el-button>
            <el-button @click="get8ECG(scope3.row.uid)">
              8导联静态
            </el-button>
            <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联holter
            </el-button>
            <el-button slot="reference" type="primary">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="孕期" prop="pregnancy" width="60"></el-table-column>
      <el-table-column label="关联检测">
        <el-table-column label="体重(kg)" prop="weight" width="80"> </el-table-column>
        <el-table-column label="血压(mmHg)" prop="pressure" width="80"> </el-table-column>
        <el-table-column label="血糖(mmol/L)" prop="sugar" width="80">
        </el-table-column>
        <el-table-column label="血氧(%)" prop="oxygen" width="80"> </el-table-column>
        <el-table-column label="尿检" prop="urine" width="80">
          <template v-slot="scope">
            <el-tag :type="scope.row.urine === '正常' ? 'success' : 'warning'" disable-transitions
              style="font-size: 14px;">{{ scope.row.urine }}
            </el-tag>
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-col :span="15">
            <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.remarks">
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" plain size="small">保存</el-button>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope">
          <el-link type="primary" @click="psInfo(scope.row)">{{ '个人信息表' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-link type="primary" @click="">{{ '上报' }}</el-link>
          <span>&nbsp&nbsp&nbsp&nbsp</span>
          <el-button type="danger" @click="handleDelete(scope.$index, scope.row)" size="mini"><i
              class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- <el-dialog :visible.sync="psInfoVisible">
        <ps_information/>
      </el-dialog> -->
    <div style="padding-top: 40px">
      <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
      <el-col :span="6">
        <el-input :rows="1" placeholder="请输入手机号或用户名" v-model="userNameOrPhone">
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button @click="addNewUser" type="primary">添加</el-button>
      </el-col>
    </div>
    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information
  },
  data() {
    //这里存放数据
    return {
      size: '',
      psInfoVisible: false,
      uidCurrent: '',
      tableData: [
        {
          uid: '1',
          uname: '田圆圆',
          phone: '13244567890',
          weight: 68,
          pressure: '120/90',
          sugar: 2,
          oxygen: 98,
          urine: '正常',
          pregnancy: '18周',
        },
        {
          uid: '2',
          uname: '张异凡',
          phone: '18023457332',
          weight: 70,
          pressure: '112/76',
          sugar: 4,
          oxygen: 99,
          urine: '异常',
          pregnancy: '20周',

        },
        {
          uid: '157',
          uname: '刘金磊',
          phone: '13598856743',
          weight: 77,
          pressure: '130/88',
          sugar: 14.1,
          oxygen: 98,
          urine: '正常',
          pregnancy: '30周',

        },
      ],
      userNameOrPhone: '',
      search: ''
    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    handleDelete(index, row) {
      console.log("看看删除啥", index, row);
      console.log(row.uid, row.reid, this.$store.state.doctor_station.docid);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/deleteUser"),
          method: "post",
          data: this.$http.adornData({
            docId: this.$store.state.women_baby.uid,
            uid: row.uid,
            reid: row.reid
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList()
          } else {
            console.log(data);
            this.$message({
              type: 'info',
              message: data.msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // 获取小屋成员List
    getUserList() {
      console.log('看看小屋uid', this.$store.state.women_baby.uid)
      this.$http({
        url: this.$http.adornUrl('/enterprise/enterpriseuserinfo/ListUserAll'),
        method: 'get',
        params: this.$http.adornParams({
          eid: this.$store.state.women_baby.uid,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('小屋list')
          console.log(data)
          this.tableData = data.user_info
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    // 检测项目跳转
    getECG(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: uid,
        }
      })
    },
    getECGHolter(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        }
      })
    },

    getBloodFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodFatJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyGlucose(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyGlucoseJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodOxygen(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodPressure(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        }
      })
    },

    getBio(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBioJump',
        query: {
          uid: uid,
        }
      })
    },

    getBodyFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: uid,
        }
      })
    },
    getThreeInOne(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryThreeInOneJump',
        query: {
          uid: uid,
        }
      })
    },
    getUrine(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryUrineJump',
        query: {
          uid: uid,
        }
      })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
    getTem(e) {
      console.log('看看uid传过来了没', e)
      let uid = e
      this.$router.push({
        name: 'HistoryTem_women',
        query: {
          uid: uid,
        }
      })
    },
    getBaby(e) {
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBabyJump',
        query: {
          uid: uid,
        }
      })
    },
    // 最近检测记录
    getRecentRecord(e) {
      this.$http({
        url: this.$http.adornUrl('/personal/clouddoctor/app/getRecentRecord'),
        method: 'post',
        params: this.$http.adornParams({
          uid: e,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0 && data.msg === "success") {

          console.log("看看数据")
          console.log(data)
          let con = data.zonghe
          console.log(con)
          if (con["flag"] === 1) {
            this.zonghe = "近期检测项目存在异常，请咨询医生！异常项目："
            let keys_name = Object.keys(con)
            console.log(keys_name)
            for (let i in Object.keys(con)) {
              let key = keys_name[i]
              if (key != "flag") this.zonghe = this.zonghe + key + ","
            }
            this.zonghe = this.zonghe.slice(0, -1)
          } else {
            this.zonghe = "近期检测正常！"
          }

          this.HealthSelfReport = data.itemsRecord.healthSelfReport
          // 获取所有键，然后遍历键，获取键对应的值，再将值设置为新的值
          if (data.itemsRecord.ecg != null) {
            Object.keys(this.ecg).forEach(key => {
              let value = data.itemsRecord.ecg[key];
              this.ecg[key] = value;
            });
          }
          if (data.itemsRecord.bloodPressure != null) {
            Object.keys(this.bloodPressure).forEach(key => {
              let value = data.itemsRecord.bloodPressure[key];
              this.bloodPressure[key] = value;
            });
          }

          if (data.itemsRecord.urine != null) {
            Object.keys(this.urine).forEach(key => {
              let value = data.itemsRecord.urine[key];
              this.urine[key] = value;
            });
          }

          if (data.itemsRecord.bloodOxygen != null) {
            Object.keys(this.bloodOxygen).forEach(key => {
              let value = data.itemsRecord.bloodOxygen[key];
              this.bloodOxygen[key] = value;
            });
          }

          if (data.itemsRecord.bloodSugar != null) {
            Object.keys(this.bloodSugar).forEach(key => {
              let value = data.itemsRecord.bloodSugar[key];
              this.bloodSugar[key] = value;
            });
          }


          if (data.itemsRecord.bloodLipids != null) {
            Object.keys(this.bloodLipids).forEach(key => {
              let value = data.itemsRecord.bloodLipids[key];
              this.bloodLipids[key] = value;
            });
          }

          if (data.itemsRecord.threeInOne != null) {
            Object.keys(this.threeInOne).forEach(key => {
              let value = data.itemsRecord.threeInOne[key];
              this.threeInOne[key] = value;
            });
          }


          if (data.itemsRecord.temperature != null) {
            Object.keys(this.temperature).forEach(key => {
              let value = data.itemsRecord.temperature[key];
              this.temperature[key] = value;
            });
          }

          if (data.itemsRecord.bodyFat != null) {
            Object.keys(this.bodyFat).forEach(key => {
              let value = data.itemsRecord.bodyFat[key];
              this.bodyFat[key] = value;
            });

          }


        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    addNewUser() {
      if (this.addUid == "") {
        this.$alert('未填写用户信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        console.log("看看eId", this.$store.state.health_station.eid, this.userNameOrPhone);
        console.log(typeof (this.$store.state.health_station.eid));
        let that = this
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/addUserRelationPhoneOrName"),
          method: "get",
          params: this.$http.adornParams({
            eid: this.$store.state.women_baby.uid,
            phone: that.userNameOrPhone,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.userNameOrPhone = ""
            this.getList()
          } else {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
      }
    },
    // updateRealName(e) {
    //   this.$http({
    //     url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateUserRealName"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       uid: e.uid,
    //       realName: e.realName
    //     }),
    //   }).then(({ data }) => {
    //     if (data && data.code === 0) {
    //       console.log("看看数据2023年1月9日");
    //       console.log(data);
    //     } else {
    //       console.log(data);
    //       this.$message({
    //         message: data.msg,
    //         type: "warning",
    //       });
    //     }
    //   });
    // },
    updateRemark(e) {
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/updateRemarks"),
        method: "post",
        data: this.$http.adornData({
          reid: e.reid,
          remarks: e.remarks
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid

    },
    cellStyle({ row, column, rowIndex, columnIndex }) {

      if (columnIndex == 7) {
        if (row.sugar > 6.1) {
          return 'background: #F6BEB8 ';
        }
        if (row.sugar < 3.9) {
          return 'background: rgb(234 215 79) ';
        }
      }


    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$store.state.women_baby.uid)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getUserList()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}
</style>